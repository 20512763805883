@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&family=Platypi&family=Merriweather&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 1.8s ease-out forwards;
}

/* Fade In Up Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Scrollbar Styles */
@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* User Select Styles for Textareas */
textarea {
  -webkit-touch-callout: auto; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto; /* Non-prefixed version, currently supported by Chrome and Opera */
}
